import type { ChainConfig } from '../..'

export const GNOSIS = {
  name: 'gnosis',
  explorerUrl: 'https://gnosisscan.io',
  nativeAsset: {
    assetAddress: '0x0000000000000000000000000000000000000000',
    name: 'xDAI',
    decimals: 18,
    symbol: 'XDAI',
  },
  slip44: 700,
  wrappedNativeAsset: '0xe91D153E0b41518A2Ce8Dd3D7944Fa863463a97d',
  blockscoutApiUrl: 'https://gnosis.blockscout.com/api',
  etherscanApiUrl: 'https://api.gnosisscan.io/api',
  publicRpcUrls: [
    'https://gnosis.drpc.org',
    'https://1rpc.io/gnosis',
    'https://rpc.gnosischain.com',
    'https://rpc.chiado.gnosis.gateway.fm',
  ],
  getPrivateRpcUrls: () => {
    const gnosis: string[] = []
    process.env.ANKR_API_KEY && gnosis.push(`https://rpc.ankr.com/gnosis/${process.env.ANKR_API_KEY}`)
    process.env.ALCHEMY_GNOSIS_API_KEY &&
      gnosis.push(`https://gnosis-mainnet.g.alchemy.com/v2/${process.env.ALCHEMY_GNOSIS_API_KEY}`)
    process.env.QUICKNODE_GNOSIS_API_KEY &&
      gnosis.push(`https://orbital-spring-general.xdai.quiknode.pro/${process.env.QUICKNODE_GNOSIS_API_KEY}`)
    return gnosis
  },
} as const satisfies ChainConfig
