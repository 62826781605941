import type { ChainConfig } from '../..'
import { ETH } from '../assets'

export const SWELLCHAIN = {
  name: 'swellchain',
  explorerUrl: 'https://explorer.swellnetwork.io',
  nativeAsset: ETH,
  slip44: 60,
  wrappedNativeAsset: '0x4200000000000000000000000000000000000006',
  blockscoutApiUrl: 'https://explorer.swellnetwork.io/api',
  publicRpcUrls: ['https://rpc.ankr.com/swell', 'https://swell-mainnet.alt.technology'],
  etherscanApiUrl: 'https://api.swellchainscan.io/api',
  getPrivateRpcUrls: () => {
    const swell: string[] = []
    process.env.INFURA_API_KEY && swell.push(`https://swellchain-mainnet.infura.io/v3/${process.env.INFURA_API_KEY}`)
    process.env.ANKR_API_KEY && swell.push(`https://rpc.ankr.com/swell/${process.env.ANKR_API_KEY}`)
    return swell
  },
} as const satisfies ChainConfig
