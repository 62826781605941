import { StrictMode } from 'react'
import ReactDOM from 'react-dom/client'
import '@vaultsfyi/common/styles/global.css'
import '@/index.css'
import App from './App'

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.getRegistrations().then((registrations) => {
    for (const registration of registrations) {
      registration.unregister()
    }
  })
}

if ('caches' in window) {
  caches.keys().then(function (cacheNames) {
    cacheNames.forEach(function (cacheName) {
      caches.delete(cacheName)
    })
  })
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <StrictMode>
    <App />
  </StrictMode>
)
