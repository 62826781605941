import { Button } from '@/components/atoms/Button'
import { ArrowIcon } from '@/components/atoms/Icons'
import { Panel } from '@/components/atoms/Panel'
import {
  VaultApyTile,
  VaultBasicStatistics,
  VaultExpandedStatistics,
  VaultItemContent,
  VaultMainInfo,
  VaultStatisticsWrapper,
} from '@/components/organisms/VaultItem'
import { vaultMock } from '@/constants/mocks'
import { APY_CHART_DATA_MOCK } from '@/constants/mocks/charts/apyChartMock'
import { useWidthBreakpoint } from '@/hooks'
import { Breakpoints } from '@/styles/breakpoints'
import type { ApyModes } from '@/types'

interface VaultItemPlaceholderProps {
  apyMode: ApyModes
  period?: number
}

export const OptimizerVaultItemPlaceholder = ({ apyMode, period }: VaultItemPlaceholderProps) => {
  const { address, name, network, asset, tags } = vaultMock
  const isTablet = useWidthBreakpoint(Breakpoints.tablet)

  return (
    <Panel className="rounded-none p-0 shadow-card">
      <VaultItemContent>
        <VaultMainInfo address={address} network={network} name={name} asset={asset} tags={tags} />
        <VaultStatisticsWrapper>
          {isTablet ? (
            <VaultBasicStatistics vault={vaultMock} period={period} isHighTvlImpact={true} />
          ) : (
            <VaultExpandedStatistics vault={vaultMock} period={period} isHighTvlImpact={true} />
          )}
          {!isTablet && <VaultApyTile apyChartData={APY_CHART_DATA_MOCK} apyMode={apyMode} />}
        </VaultStatisticsWrapper>
        <div className="flex w-full flex-col gap-2 md:max-w-[136px]">
          <Button variant="filled" className="h-10 font-bold text-base disabled:opacity-100" disabled>
            Deposit
          </Button>
          <Button variant="gray" className="h-10 px-3 text-base disabled:opacity-100" disabled>
            More details
            <ArrowIcon direction="down" className="ml-auto" />
          </Button>
        </div>
      </VaultItemContent>
    </Panel>
  )
}
