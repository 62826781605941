import { NavLink, useLoaderData } from 'react-router'

import { Button } from '@/components/atoms/Button'
import { DatabaseIcon, DocumentIcon, LogoutIcon } from '@/components/atoms/Icons'
import { Image } from '@/components/atoms/Image'
import { Panel } from '@/components/atoms/Panel'
import { Page } from '@/components/molecules'
import { routes } from '@/config'
import { BACKEND_URL } from '@/constants'
import type { IsAuthorizedResponse } from '@/utils/checkIsAuthorized'

function WallbreakerPage() {
  const { authorized, image, name } = useLoaderData() as IsAuthorizedResponse

  return (
    <Page title="Wallbreaker">
      <Panel className="mx-auto flex w-fit min-w-56 flex-col items-center justify-center gap-4 p-4 [&_a_span]:w-full [&_a_span]:text-center">
        {authorized && (
          <div className="mb-2 flex w-full flex-col items-center gap-4">
            <h2 className="font-bold">Logged in as</h2>
            <div className="flex w-full items-center justify-center gap-2 rounded-lg border border-background-300 p-2">
              {image && <Image src={image} alt={`${name} profile photo`} />}
              {name}
            </div>
          </div>
        )}
        <Button variant="gray" className="w-full gap-2" asChild>
          <NavLink to={routes.dataUpdater}>
            <DatabaseIcon size={24} />
            <span>Data Updater</span>
          </NavLink>
        </Button>
        <Button variant="gray" className="w-full gap-2" asChild>
          <NavLink to={routes.protocolUpdater}>
            <DatabaseIcon size={24} />
            <span>Protocol Updater</span>
          </NavLink>
        </Button>
        <Button variant="gray" className="w-full gap-2" asChild>
          <NavLink to={routes.aboutChange}>
            <DocumentIcon size={24} />
            <span>About Changer</span>
          </NavLink>
        </Button>
        {authorized && (
          <Button
            variant="gray"
            className="mt-2 w-full gap-2 border-red-500 bg-red-500/10 text-red-500 [&>span]:w-full"
            asChild
          >
            <a href={`${BACKEND_URL}/oauth2/logout`}>
              <span>Logout</span>
              <LogoutIcon size={20} />
            </a>
          </Button>
        )}
      </Panel>
    </Page>
  )
}

export const Component = () => WallbreakerPage()
