import type { ChainConfig } from '../..'
import { ETH } from '../assets'

export const ARBITRUM = {
  name: 'arbitrum',
  explorerUrl: 'https://arbiscan.io',
  nativeAsset: ETH,
  slip44: 60,
  wrappedNativeAsset: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
  blockscoutApiUrl: 'https://arbitrum.blockscout.com/api',
  etherscanApiUrl: 'https://api.arbiscan.io/api',
  publicRpcUrls: ['https://arbitrum.drpc.org', 'https://1rpc.io/arb', 'https://arbitrum.meowrpc.com'],
  getPrivateRpcUrls: () => {
    const arbitrum: string[] = []
    process.env.INFURA_API_KEY && arbitrum.push(`https://arbitrum-mainnet.infura.io/v3/${process.env.INFURA_API_KEY}`)
    process.env.ALCHEMY_ARBITRUM_API_KEY &&
      arbitrum.push(`https://arb-mainnet.g.alchemy.com/v2/${process.env.ALCHEMY_ARBITRUM_API_KEY}`)
    process.env.ANKR_API_KEY && arbitrum.push(`https://rpc.ankr.com/arbitrum/${process.env.ANKR_API_KEY}`)
    return arbitrum
  },
} as const satisfies ChainConfig
