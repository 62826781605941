import { cn } from '@/utils/cn'
import { type VariantProps, cva } from 'class-variance-authority'
import type { HTMLAttributes } from 'react'

export const tileVariants = cva(cn('relative rounded-lg', '[&:has([role=tooltip])]:pr-8'), {
  variants: {
    variant: {
      primary: 'border border-background-300 bg-background-200',
      secondary: 'border border-background-300',
    },
    spacing: {
      small: 'p-2',
      medium: 'p-4',
      large: 'p-6',
    },
  },
  defaultVariants: {
    variant: 'primary',
    spacing: 'large',
  },
})

export const Tile = ({
  className,
  variant,
  spacing,
  children,
  ...props
}: HTMLAttributes<HTMLDivElement> & VariantProps<typeof tileVariants>) => {
  return (
    <div className={cn(tileVariants({ className, variant, spacing }))} data-role="tile" {...props}>
      {children}
    </div>
  )
}
