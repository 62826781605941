import { isSafari } from '@/utils'
import { cn } from '@/utils/cn'
import { type VariantProps, cva } from 'class-variance-authority'
import { type HTMLAttributes, type TdHTMLAttributes, type ThHTMLAttributes, forwardRef } from 'react'

export const Table = forwardRef<HTMLTableElement, HTMLAttributes<HTMLTableElement>>(({ className, ...props }, ref) => (
  <table ref={ref} className={cn('w-full table-fixed rounded-2xl', className)} {...props} />
))
Table.displayName = 'Table'

export const TableHeader = forwardRef<HTMLTableSectionElement, HTMLAttributes<HTMLTableSectionElement>>(
  ({ className, ...props }, ref) => <thead ref={ref} className={cn('max-w-full', className)} {...props} />
)
TableHeader.displayName = 'TableHeader'

const tableHeadWrapperVariants = cva(cn('h-full w-fit'), {
  variants: {
    align: {
      left: 'mr-auto',
      center: 'mx-auto',
      right: 'ml-auto',
    },
  },
  defaultVariants: {
    align: 'left',
  },
})

export type TableHeadProps = ThHTMLAttributes<HTMLTableCellElement> & VariantProps<typeof tableHeadWrapperVariants>

export const TableHead = forwardRef<HTMLTableCellElement, TableHeadProps>(
  ({ className, align, children, ...props }, ref) => (
    <th
      ref={ref}
      className={cn(
        'max-w-full overflow-hidden text-ellipsis whitespace-nowrap border-background-300 border-bottom bg-background-100 p-1 sm:last-of-type:pr-3 sm:first-of-type:pl-3',
        className
      )}
      {...props}
    >
      <div className={cn(tableHeadWrapperVariants({ align }))}>{children}</div>
    </th>
  )
)
TableHead.displayName = 'TableHead'

export const TableBody = forwardRef<HTMLTableSectionElement, HTMLAttributes<HTMLTableSectionElement>>(
  ({ className, ...props }, ref) => <tbody ref={ref} className={cn('max-w-full', className)} {...props} />
)
TableBody.displayName = 'TableBody'

export const TableRow = forwardRef<HTMLTableRowElement, HTMLAttributes<HTMLTableRowElement>>(
  ({ className, ...props }, ref) => (
    <tr
      ref={ref}
      className={cn(
        'relative [&:last-of-type_td]:border-b-0',
        !isSafari() && '[&:last-of-type_td_[data-role="table-row-button"]]:before:rounded-b-2xl',
        className
      )}
      {...props}
    />
  )
)
TableRow.displayName = 'TableRow'

const tableCellVariants = cva(cn('group bg-background-100 px-1 py-3 sm:last-of-type:pr-3 sm:first-of-type:pl-3'), {
  variants: {
    variant: {
      default: 'border-y border-y-background-300 first-of-type:pl-2 last-of-type:pr-2',
      mobile: cn(
        'block sm:table-cell',
        'p-0 sm:px-1 sm:py-3 sm:last-of-type:pr-3 sm:first-of-type:pl-3',
        'gap-2 sm:gap-[unset]',
        'border-y-none sm:border-y sm:border-y-background-300'
      ),
    },
    align: {
      left: '[&>div]:mr-auto',
      center: '[&>div]:mx-auto',
      right: '[&>div]:ml-auto',
    },
  },
  defaultVariants: {
    variant: 'default',
    align: 'left',
  },
})

export type TableCellProps = TdHTMLAttributes<HTMLTableCellElement> & VariantProps<typeof tableCellVariants>

export const TableCell = forwardRef<HTMLTableCellElement, TableCellProps>(
  ({ className, variant, align, children, ...props }, ref) => (
    <td ref={ref} className={cn(tableCellVariants({ className, variant, align }))} {...props}>
      <div
        className={cn(
          'h-full w-fit max-w-full overflow-hidden text-ellipsis whitespace-nowrap group-[&:not([data-row-action])]:relative group-[&:not([data-row-action])]:z-1'
        )}
      >
        {children}
      </div>
    </td>
  )
)
TableCell.displayName = 'TableCell'
