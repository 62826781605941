import type { ChainConfig } from '../..'

export const POLYGON = {
  name: 'polygon',
  explorerUrl: 'https://polygonscan.com',
  nativeAsset: {
    assetAddress: '0x0000000000000000000000000000000000001010',
    name: 'Polygon Ecosystem Token',
    decimals: 18,
    symbol: 'POL',
  },
  slip44: 966,
  wrappedNativeAsset: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
  blockscoutApiUrl: 'https://polygon.blockscout.com/api',
  etherscanApiUrl: 'https://api.polygonscan.com/api',
  publicRpcUrls: ['https://polygon.llamarpc.com', 'https://polygon.drpc.org', 'https://1rpc.io/matic'],
  getPrivateRpcUrls: () => {
    const polygon: string[] = []
    process.env.INFURA_API_KEY && polygon.push(`https://polygon-mainnet.infura.io/v3/${process.env.INFURA_API_KEY}`)
    process.env.ALCHEMY_POLYGON_API_KEY &&
      polygon.push(`https://polygon-mainnet.g.alchemy.com/v2/${process.env.ALCHEMY_POLYGON_API_KEY}`)
    process.env.ANKR_API_KEY && polygon.push(`https://rpc.ankr.com/polygon/${process.env.ANKR_API_KEY}`)
    return polygon
  },
} as const satisfies ChainConfig
