import type { BackendVault } from '@/types'
import { getLatestBenchmarks } from '@/utils/getLatestBenchmarks'
import { Await, useLoaderData } from 'react-router'
import { InfoIcon } from '../atoms/Icons'
import { Tooltip, TooltipContent } from '../atoms/Tooltip'

export const Benchmarks = () => {
  const { vaults } = useLoaderData() as { vaults: BackendVault[] }
  return (
    <Await resolve={vaults}>
      {(vaults) => {
        if (!vaults) return undefined
        const benchmarks = getLatestBenchmarks(vaults)
        return (
          <Tooltip>
            <div className="relative flex h-fit min-h-8 flex-wrap items-center justify-center overflow-hidden rounded-2xl border-2 border-background-100 bg-background-100 shadow-card">
              <div className="mx-2 my-1 flex items-center gap-2">
                <h6 className="text-center font-normal text-foreground-100 text-sm">vaults.fyi benchmark rates</h6>
                <InfoIcon />
              </div>
              <ul className="flex h-full flex-grow bg-background-100">
                {benchmarks.map(({ label, value }) => (
                  <li
                    key={label}
                    className="flex flex-grow items-center justify-center gap-1 border-background-100 border-s-2 bg-blue-500/10 px-2 py-1"
                  >
                    <span className="text-foreground-300 uppercase">{label}:</span>
                    <span className="font-bold text-blue-500">{value}</span>
                  </li>
                ))}
              </ul>
            </div>
            <TooltipContent className="w-52 text-left md:w-96" sideOffset={12}>
              Benchmark rates are weighted avgs. composed of the following:
              <br />
              <span className="font-semibold">USD:</span> Aave v3 USDC, Aave v3 USDT, sDAI, Compound v3 USDC
              <br />
              <span className="font-semibold">ETH:</span> Lido stETH, ether.fi eETH, Coinbase cbETH, Rocket Pool rETH
            </TooltipContent>
          </Tooltip>
        )
      }}
    </Await>
  )
}
