import * as CheckboxPrimitives from '@radix-ui/react-checkbox'

import { CheckIcon } from './Icons'

import { cn } from '@/utils/cn'

export const Checkbox = (props: CheckboxPrimitives.CheckboxProps) => (
  <CheckboxPrimitives.Root
    className={cn(
      'flex size-4 items-center justify-center rounded border border-background-300 bg-background-100 p-0 text-background-100 outline-none transition-all',
      'hocus:border-blue-500',
      'data-[state=checked]:border-blue-500 data-[state=checked]:bg-blue-500'
    )}
    {...props}
  >
    <span className="text-inherit">
      <CheckIcon />
    </span>
  </CheckboxPrimitives.Root>
)
