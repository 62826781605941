import type { ChainConfig } from '../..'

export const CELO = {
  name: 'celo',
  explorerUrl: 'https://celoscan.io',
  nativeAsset: {
    assetAddress: '0x471ece3750da237f93b8e339c536989b8978a438',
    decimals: 18,
    name: 'Celo native asset',
    symbol: 'CELO',
  },
  slip44: 52752,
  wrappedNativeAsset: '0x471ece3750da237f93b8e339c536989b8978a438',
  blockscoutApiUrl: 'https://celo.blockscout.com/api',
  etherscanApiUrl: 'https://api.celoscan.io/api',
  publicRpcUrls: ['https://forno.celo.org', 'https://celo.drpc.org'],
  getPrivateRpcUrls: () => {
    const swell: string[] = []
    process.env.INFURA_API_KEY && swell.push(`https://celo-mainnet.infura.io/v3/${process.env.INFURA_API_KEY}`)
    process.env.ANKR_API_KEY && swell.push(`https://rpc.ankr.com/celo/${process.env.ANKR_API_KEY}`)
    return swell
  },
} as const satisfies ChainConfig
