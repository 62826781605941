import { useEffect } from 'react'
import { generatePath } from 'react-router'

import { WarningIcon } from '@/components/atoms/Icons'
import { Tooltip, TooltipContent } from '@/components/atoms/Tooltip'
import { routes } from '@/config'
import { formatNumber, trackEvent } from '@/utils'
import { ANALYTICS_URL } from '@vaultsfyi/common'

interface Props {
  address: string
  network: string
  name: string
  amount: number | undefined
  percentage: number
  symbol: string
}

export const HighTvlImpactBadge = ({ amount, percentage, symbol, address, network, name }: Props) => {
  useEffect(() => {
    trackEvent({
      event: 'Yield Search',
      params: {
        name,
        link: ANALYTICS_URL + generatePath(routes.vaultPreview, { chainName: network, address }),
        network,
        address,
      },
    })
  }, [address, name, network])

  return (
    <span className="flex min-h-8 items-center justify-center bg-orange-500/30 px-4 py-2 text-center font-bold text-orange-500 leading-none">
      <Tooltip className="hocus:text-foreground-500">
        <div className="flex w-fit items-center gap-1">
          <WarningIcon /> <span>APY Impact Warning</span>
        </div>
        <TooltipContent className="font-normal text-foreground-300">
          <p>
            Lending {formatNumber(amount ?? 0)} {symbol} ({percentage.toFixed(2)}% of current TVL)
            <br />
            may negatively impact this vault’s returns.
          </p>
        </TooltipContent>
      </Tooltip>
    </span>
  )
}
