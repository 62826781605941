import type { ChainConfig } from '../..'
import { ETH } from '../assets'

export const OPTIMISM = {
  name: 'optimism',
  explorerUrl: 'https://optimistic.etherscan.io',
  nativeAsset: ETH,
  slip44: 60,
  wrappedNativeAsset: '0x4200000000000000000000000000000000000006',
  blockscoutApiUrl: 'https://optimism.blockscout.com/api',
  etherscanApiUrl: 'https://api-optimistic.etherscan.io/api',
  publicRpcUrls: ['https://optimism.drpc.org', 'https://optimism.llamarpc.com', 'https://1rpc.io/op'],
  getPrivateRpcUrls: () => {
    const optimism: string[] = []
    process.env.INFURA_API_KEY && optimism.push(`https://optimism-mainnet.infura.io/v3/${process.env.INFURA_API_KEY}`)
    process.env.ALCHEMY_OPTIMISM_API_KEY &&
      optimism.push(`https://opt-mainnet.g.alchemy.com/v2/${process.env.ALCHEMY_OPTIMISM_API_KEY}`)
    process.env.ANKR_API_KEY && optimism.push(`https://rpc.ankr.com/optimism/${process.env.ANKR_API_KEY}`)
    return optimism
  },
} as const satisfies ChainConfig
