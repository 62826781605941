import type { Asset } from '@vaultsfyi/common'

import { VaultTags } from '../styles'
import { VaultBasics } from './VaultBasics'
import { VaultNameLink } from './VaultNameLink'

import { BookmarkButton } from '@/components/molecules/BookmarkButton'
import { VaultTag } from '@/components/molecules/VaultTag'
import type { useVaultTrackingParams } from '@/hooks'
import { useBookmarks } from '@/providers/BookmarksProvider'
import type { BackendTag } from '@/types'
import { trackVaultButtonEvent } from '@/utils'
import { type Address, isAddressEqual } from 'viem'

interface Props {
  address: Address
  network: string
  name: string
  asset: Asset
  tags: BackendTag[]
  trackingParams?: ReturnType<typeof useVaultTrackingParams>
}

export const VaultMainInfo = ({ address, network, name, asset, tags, trackingParams }: Props) => {
  const { bookmarks, addBookmark, removeBookmark } = useBookmarks()
  const isBookmarked = bookmarks.some(
    (bookmark) => isAddressEqual(bookmark.address, address) && bookmark.network === network
  )

  const handleBookmark = () => {
    if (isBookmarked) {
      removeBookmark({ address, network })
    }
    if (!isBookmarked) {
      addBookmark({ address, network })
    }
  }

  const handlePreviewLinkEvent = () =>
    trackVaultButtonEvent({
      event: 'Preview Button',
      params: trackingParams,
    })

  return (
    <div className="flex max-w-full flex-grow flex-row justify-between gap-2 overflow-hidden sm:flex-col sm:flex-wrap sm:justify-start md:w-52">
      <div className="flex max-w-full flex-shrink flex-grow items-start gap-2 overflow-hidden sm:flex-shrink-0 sm:flex-grow-0">
        <BookmarkButton onClick={handleBookmark} isActive={isBookmarked} />
        {address ? (
          <VaultNameLink address={address} network={network} name={name} onClick={handlePreviewLinkEvent} />
        ) : (
          <span className="inline-block max-w-full overflow-hidden text-ellipsis whitespace-nowrap text-2xl text-foreground-300 leading-none underline md:overflow-auto md:whitespace-normal">
            {name}
          </span>
        )}
      </div>
      <VaultBasics asset={asset} network={network} />
      {tags && (
        <div className="hidden sm:block">
          <VaultTags>
            {tags.map((tag) => (
              <li key={tag.id}>
                <VaultTag tag={tag} />
              </li>
            ))}
          </VaultTags>
        </div>
      )}
    </div>
  )
}
