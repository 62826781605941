export const ETHERSCAN_API_URL: string = 'https://api.etherscan.io/v2/api'

export const APP_URL = 'https://app.vaults.fyi'
export const ANALYTICS_URL = 'https://analytics.vaults.fyi'
export const LANDING_URL = 'https://vaults.fyi'

export const API_URL = 'https://api.vaults.fyi'
export const DOCS_URL = 'https://docs.vaults.fyi'

export const X_URL = 'https://x.com/vaultsfyi'
export const FARCASTER_URL = 'https://warpcast.com/vaultsfyi'
export const TELEGRAM_URL = 'https://t.me/vaultsfyisupport'
