import { VaultsListRowDetails } from './VaultsListRowDetails'

import type { ApyModes, VaultForList } from '@/types'
import type { Subdomain } from '@/types/Subdomain'
import { cn } from '@/utils/cn'

interface VaultsListSubrowProps {
  vault: VaultForList
  apyMode: ApyModes
  columnsCount: number
  subdomain: Subdomain | undefined
}

export const VaultsListSubrow = ({ vault, apyMode, columnsCount, subdomain }: VaultsListSubrowProps) => {
  return (
    <tr
      className={cn(
        '-translate-y-2 flex rounded-b-lg bg-background-300 shadow-card',
        'sm:table-row sm:translate-y-0 sm:rounded-none sm:shadow-none'
      )}
    >
      <td className="w-full p-3 sm:w-auto" colSpan={columnsCount}>
        <VaultsListRowDetails vault={vault} apyMode={apyMode} subdomain={subdomain} />
      </td>
    </tr>
  )
}
