import type { BackendTag } from '@/types'
import { Badge } from '../atoms/Badge'

interface VaultTagProps {
  tag: BackendTag
}

export const VaultTag = ({ tag }: VaultTagProps) => {
  return <Badge style={{ backgroundColor: `${tag.color}26`, color: tag.color }}>{tag.name}</Badge>
}
