import type { DbWarning } from '@vaultsfyi/common'
import { useState } from 'react'

import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/atoms/Accordion'
import { Button } from '@/components/atoms/Button'
import { ArrowIcon, CheckIcon, PlusCircleIcon } from '@/components/atoms/Icons'
import { LabeledInputField } from '@/components/molecules'
import { trpcQueryClient } from '@/config/trpc'
import type { BackendVaultDetailed, EditResultProps, RevalidatorProps } from '@/types'
import { PostResult } from './PostResult'

interface AddWarningProps extends RevalidatorProps {
  vault: BackendVaultDetailed
  addWarning: (warning: DbWarning) => void
}

export const AddWarning = ({ revalidate, addWarning: addWarningState, vault }: AddWarningProps) => {
  const [warningContent, setWarningContent] = useState<string>('')
  const [warningEndTime, setWarningEndTime] = useState<string>('')

  const [isPostButtonDisabled, setPostButtonDisabled] = useState<boolean>(false)
  const [postResult, setPostResult] = useState<EditResultProps>()

  const addWarning = async () => {
    try {
      setPostButtonDisabled(true)
      setPostResult({ message: 'Posting', status: 'Pending' })
      const { message, warning } = await trpcQueryClient.addWarning.mutate({
        content: warningContent,
        end: new Date(warningEndTime).toISOString(),
        start: new Date().toISOString(),
        address: vault.address,
        network: vault.network,
      })
      setPostResult({ message: message, status: 'Success' })
      addWarningState(warning)
      revalidate()
    } catch (error) {
      setPostResult({ message: `Error: ${error}`, status: 'Failed' })
    } finally {
      setPostButtonDisabled(false)
    }
  }

  return (
    <Accordion type="multiple">
      <AccordionItem
        className="overflow-hidden rounded-2xl border border-background-300 bg-background-100"
        value="Add warning accordion"
      >
        <AccordionContent>
          <div className="flex flex-col gap-4 p-4">
            <LabeledInputField
              id="warningContent"
              label="Warning text"
              name="warningContent"
              value={warningContent}
              onChange={(e) => setWarningContent(e.target.value)}
            />
            <LabeledInputField
              label="Expiration time"
              id="warningEnd"
              name="warningEnd"
              type="datetime-local"
              value={warningEndTime}
              onChange={(e) => setWarningEndTime(e.target.value)}
            />
            <Button variant="gray" onClick={addWarning} disabled={isPostButtonDisabled}>
              <CheckIcon />
              Add warning
            </Button>
            {postResult && <PostResult {...postResult} />}
          </div>
        </AccordionContent>
        <AccordionTrigger className="group flex h-10 w-full cursor-pointer justify-center rounded-none border-none bg-background-200 px-4 text-center hocus:text-blue-500 text-foreground-300 outline-none">
          <PlusCircleIcon /> Add warning
          <ArrowIcon className="group-data-[state=open]:-scale-y-100 transition-transform" direction="down" />
        </AccordionTrigger>
      </AccordionItem>
    </Accordion>
  )
}
