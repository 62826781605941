export const CHART_COLORS = {
  red: 'var(--chart-red)',
  cyan: 'var(--chart-cyan)',
  orange: 'var(--chart-orange)',
  indigo: 'var(--chart-indigo)',
  yellow: 'var(--chart-yellow)',
  purple: 'var(--chart-purple)',
  coral: 'var(--chart-coral)',
  teal: 'var(--chart-teal)',
  magenta: 'var(--chart-magenta)',
  lime: 'var(--chart-lime)',
  blue: 'var(--chart-blue)',
  amber: 'var(--chart-amber)',
  violet: 'var(--chart-violet)',
  pink: 'var(--chart-pink)',
  green: 'var(--chart-green)',
  plum: 'var(--chart-plum)',
}
