import type { HTMLAttributes } from 'react'
import { NavLink, generatePath } from 'react-router'

import { Button } from '@/components/atoms/Button'
import { routes } from '@/config'
import type { VaultForOptimizer } from '@/types'

interface LinkInteractionProps {
  onClick?: () => void
}

export const VaultNameLink = ({
  address,
  network,
  name,
  onClick,
  ...props
}: Pick<VaultForOptimizer, 'address' | 'network' | 'name'> &
  HTMLAttributes<HTMLHeadingElement> &
  LinkInteractionProps) => {
  const vaultPreview = generatePath(routes.vaultPreview, { chainName: network, address })

  return (
    <h4 className="inline-block max-w-full overflow-hidden" title={name} {...props}>
      <Button
        asChild
        variant="default"
        size="default"
        className="inline-block max-w-full overflow-hidden text-ellipsis whitespace-nowrap text-2xl text-foreground-500 leading-none underline md:overflow-auto md:whitespace-normal"
        onClick={onClick}
      >
        <NavLink to={vaultPreview}>{name}</NavLink>
      </Button>
    </h4>
  )
}
