import type { Network } from '@vaultsfyi/common'

import { Button } from '@/components/atoms/Button'
import { LabeledValueContent, LabeledValueHeader, LabeledValueValue } from '@/components/molecules/LabeledValue'
import type { BackendVaultDetailed } from '@/types'
import { getExplorerLinkFromValue, shortenString, trackVaultButtonEvent } from '@/utils'

interface Props {
  address: BackendVaultDetailed['address']
  network: BackendVaultDetailed['network']
}

export const AddressStatistic = ({ address, network }: Props) => {
  const explorerLink = getExplorerLinkFromValue(address, network as Network)

  return (
    <div>
      <LabeledValueHeader>Address</LabeledValueHeader>
      <LabeledValueContent>
        <LabeledValueValue>
          <Button
            variant="default"
            size="default"
            className="text-2xl text-blue-500 leading-none underline"
            onClick={() =>
              trackVaultButtonEvent({
                event: 'Etherscan Address',
              })
            }
            asChild
          >
            <a href={explorerLink} target="_blank" rel="noopener noreferrer">
              {shortenString(address)}
            </a>
          </Button>
        </LabeledValueValue>
      </LabeledValueContent>
    </div>
  )
}
