import { Image } from '@/components/atoms/Image'
import { ScrollArea } from '@/components/atoms/ScrollArea'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/atoms/Table'
import type { BackendProtocols } from '@/types'
import { cn } from '@/utils/cn'
import { getProtocolImage } from '@vaultsfyi/common'

type SelectedProtocol = { name: string; version: string | null; product: string }

interface ProtocolListProps {
  protocols: BackendProtocols
  selectedProtocol: SelectedProtocol
  setSelectedProtocol: (protocol: SelectedProtocol) => void
}

export function ProtocolList({ protocols, selectedProtocol, setSelectedProtocol }: ProtocolListProps) {
  return (
    <ScrollArea className="h-80 rounded-2xl md:h-[624px]">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead className="px-2">
              <p>Protocol</p>
            </TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {protocols.map(({ name, version, product }) => (
            <TableRow
              key={`${name}-${version ?? ''}-${product}`}
              className={cn(
                name === selectedProtocol.name &&
                  version === selectedProtocol.version &&
                  product === selectedProtocol.product &&
                  'bg-blue-500/10'
              )}
              onClick={() => setSelectedProtocol({ name, version, product })}
            >
              <TableCell className="px-2">
                <div className="grid grid-cols-[24px_312px] items-center gap-2" data-role="protocol-info">
                  <Image src={getProtocolImage(name)} alt={name} loading="lazy" />
                  <span className="max-w-full overflow-hidden text-ellipsis whitespace-nowrap" title="name">{`${name}${
                    version ? ` - ${version}` : ''
                  } - ${product}`}</span>
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </ScrollArea>
  )
}
