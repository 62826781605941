import { cn } from '@/utils/cn'
import type { LabelHTMLAttributes } from 'react'

export const Label = ({ className, children, ...props }: LabelHTMLAttributes<HTMLLabelElement>) => {
  return (
    <label
      className={cn(
        'flex w-fit cursor-pointer items-center gap-2 leading-none transition-colors',
        'hocus-within:text-blue-500 hocus:text-blue-500',
        className
      )}
      {...props}
    >
      {children}
    </label>
  )
}
