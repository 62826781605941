import type { ChainConfig } from '../..'
import { ETH } from '../assets'

export const UNICHAIN = {
  name: 'unichain',
  explorerUrl: 'https://uniscan.xyz',
  nativeAsset: ETH,
  slip44: 60,
  wrappedNativeAsset: '0x4200000000000000000000000000000000000006',
  blockscoutApiUrl: 'https://unichain.blockscout.com/api',
  etherscanApiUrl: 'https://api.uniscan.xyz/api',
  publicRpcUrls: ['https://unichain.drpc.org', 'https://0xrpc.io/uni'],
  getPrivateRpcUrls: () => {
    const unichain: string[] = []
    process.env.INFURA_API_KEY && unichain.push(`https://unichain-mainnet.infura.io/v3/${process.env.INFURA_API_KEY}`)
    process.env.ALCHEMY_UNICHAIN_API_KEY &&
      unichain.push(`https://unichain-mainnet.g.alchemy.com/v2/${process.env.ALCHEMY_UNICHAIN_API_KEY}`)
    return unichain
  },
} as const satisfies ChainConfig
