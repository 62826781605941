import type { ChangeEvent } from 'react'
import { useEffect, useState } from 'react'

import { Button } from '@/components/atoms/Button'
import { SaveIcon, UndoIcon } from '@/components/atoms/Icons'
import { Markdown } from '@/components/atoms/Markdown'
import { Panel } from '@/components/atoms/Panel'
import { ScrollArea } from '@/components/atoms/ScrollArea'
import { Textarea } from '@/components/atoms/Textarea'
import { LabeledField } from '@/components/molecules/LabeledField'
import { Page } from '@/components/molecules/Page'
import { trpc } from '@/config/trpc'

function ChangeAboutPage() {
  const { mutateAsync } = trpc.changeAbout.useMutation()
  const [about] = trpc.getAbout.useSuspenseQuery()

  const [newAbout, setNewAbout] = useState(about)

  useEffect(() => {
    setNewAbout(about)
  }, [about])

  const handleNewAbout = async () => {
    try {
      const { message } = await mutateAsync({ newAbout })
      window.alert(message)
    } catch (e) {
      window.alert(e)
    }
  }

  return (
    <Page title="Update about page">
      <div className="flex w-fit flex-col gap-6">
        <Panel className="flex flex-col gap-4 p-4">
          <LabeledField
            id="vaultTitle"
            label="About page content"
            headerContent={
              <Button
                variant="default"
                size="default"
                onClick={() => setNewAbout(about)}
                title="Reset vaults.fyi 'about'"
              >
                <UndoIcon />
              </Button>
            }
          >
            <Textarea
              id="vaultTitle"
              name="vaultTitle"
              rows={12}
              value={newAbout}
              onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setNewAbout(e.target.value)}
            />
          </LabeledField>
          <LabeledField label="New content preview">
            <ScrollArea className="h-64 rounded-lg border border-background-300">
              <Markdown className="px-4 py-2">{newAbout}</Markdown>
            </ScrollArea>
          </LabeledField>
          <LabeledField label="Previous content preview">
            <ScrollArea className="h-64 rounded-lg border border-background-300">
              <Markdown className="px-4 py-2">{about}</Markdown>
            </ScrollArea>
          </LabeledField>
          <Button onClick={handleNewAbout}>
            <SaveIcon size={24} /> Change about
          </Button>
        </Panel>
      </div>
    </Page>
  )
}

export const Component = () => ChangeAboutPage()
