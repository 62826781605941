import { NavLink, generatePath } from 'react-router'

import { trackVaultsListEvents } from '@/pages/VaultsListPage/hooks'

import { Button } from '@/components/atoms/Button'
import { TableCell, type TableCellProps } from '@/components/atoms/Table'
import { routes } from '@/config'
import type { VaultForList } from '@/types'

interface NameCellProps extends Pick<VaultForList, 'name' | 'address' | 'network'> {
  testId?: string
}

export const NameCell = ({ name, address, network, testId, ...props }: NameCellProps & TableCellProps) => {
  const vaultPreview = generatePath(routes.vaultPreview, { chainName: network, address })

  const handleVaultPreviewLink = () => {
    trackVaultsListEvents({
      action: 'Vault preview link clicked',
      label: `${name} vault: ${network}/${address}`,
    })
  }
  return (
    <TableCell data-cell="Vault" {...props}>
      <Button
        className="block max-w-full overflow-hidden text-ellipsis whitespace-nowrap font-bold text-base text-foreground-300"
        variant="default"
        size="default"
        data-testid={`${testId}-name-link`}
        onClick={handleVaultPreviewLink}
        asChild
      >
        <NavLink to={vaultPreview}>{name}</NavLink>
      </Button>
    </TableCell>
  )
}
