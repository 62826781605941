import type { MenuLinkBasicProps, MenuLinksBasicProps } from '.'
import { MenuLink } from '.'

import { Button } from '@/components/atoms/Button'
import { ArrowCircleIcon } from '@/components/atoms/Icons'
import { NavigationMenu, NavigationMenuList, NavigationMenuViewport } from '@/components/atoms/Navigation'
import { Separator } from '@/components/atoms/Separator'
import { trackEvent } from '@/utils'
import { APP_URL } from '@vaultsfyi/common'
import { MenuGroup } from './MenuGroup'

interface Props {
  links: Array<MenuLinkBasicProps | MenuLinksBasicProps>
}

export const DesktopNavigation = ({ links }: Props) => {
  return (
    <div className="hidden items-center gap-2 lg:flex">
      <NavigationMenu>
        <NavigationMenuList className="flex items-center gap-2">
          {links.map((link, index) => {
            if ('links' in link) {
              return <MenuGroup key={index} {...link} />
            }
            return <MenuLink key={index} {...link} />
          })}
        </NavigationMenuList>
        <NavigationMenuViewport />
      </NavigationMenu>
      <div className="hidden items-center gap-2 md:flex">
        <Separator orientation="vertical" className="mr-4 ml-2 h-6" />
        <Button
          variant="outlined"
          size="md"
          className="gap-2 border-blue-500 hocus:border-blue-500 bg-transparent px-3 text-base"
          onClick={() => trackEvent({ event: 'App redirect', params: { Source: 'Menu' } })}
          asChild
        >
          <a href={APP_URL} target="_blank" rel="noreferrer">
            Go to app
            <ArrowCircleIcon size={24} direction="right" />
          </a>
        </Button>
      </div>
    </div>
  )
}
