import { cn } from '@/utils/cn'
import { Slot } from '@radix-ui/react-slot'
import { type VariantProps, cva } from 'class-variance-authority'
import { type ButtonHTMLAttributes, forwardRef } from 'react'

const buttonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap text-sm outline-none transition-all disabled:cursor-not-allowed disabled:opacity-50',
  {
    variants: {
      variant: {
        gray: cn(
          'rounded-lg',
          'border border-background-300 bg-background-200 text-foreground-100',
          'hocus:border-background-300 hocus:bg-background-300'
        ),
        outlined: cn(
          'rounded-lg',
          'border border-blue-500/40 bg-blue-500/10 text-blue-500',
          'hocus:border-blue-500/40 hocus:bg-blue-500/25'
        ),
        filled: cn(
          'rounded-lg',
          'border border-blue-500 bg-blue-500 text-background-100',
          'hocus:border-blue-700 hocus:bg-blue-700'
        ),
        default: 'border-transparent hocus:text-blue-500',
      },
      size: {
        icon: 'h-5 w-5',
        xs: 'h-6 px-3 text-xs',
        sm: 'h-8 px-5 text-sm',
        md: 'h-10 px-5',
        lg: 'h-12 px-6',
        default: '',
      },
    },
    defaultVariants: {
      variant: 'filled',
      size: 'sm',
    },
  }
)

type ButtonVariants = VariantProps<typeof buttonVariants>

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement>, ButtonVariants {
  asChild?: boolean
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button'
    return <Comp className={cn(buttonVariants({ variant, size, className }))} ref={ref} type="button" {...props} />
  }
)
Button.displayName = 'Button'
