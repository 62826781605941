import type { viemChains } from '../constants'
import { CHAINS } from '../constants/chains/chains'

type ExtractChainNames<T extends readonly { name: string }[]> = { [K in keyof T]: T[K]['name'] }
export const NETWORKS = CHAINS.map((chain) => chain.name) as unknown as ExtractChainNames<typeof CHAINS>

type ViemNetworks = typeof viemChains

export type NetworkToIdMap = {
  [E in keyof ViemNetworks]: ViemNetworks[E]['id']
}

type NetworkToId<
  N extends NetworkToIdMap[keyof NetworkToIdMap],
  S = keyof NetworkToIdMap,
> = S extends keyof NetworkToIdMap ? (NetworkToIdMap[S] extends N ? S : never) : never

export type IdToNetworkMap = {
  [E in NetworkToIdMap[keyof NetworkToIdMap]]: NetworkToId<E>
}

export type Network = (typeof NETWORKS)[number]
export type NetworkId = ViemNetworks[keyof ViemNetworks]['id']
export type SupportedNetworkId = NetworkToIdMap[Network]
export type NetworkCaip<T extends number = SupportedNetworkId> = `eip155:${T}`
export type SupportedCaip = NetworkCaip<SupportedNetworkId>
