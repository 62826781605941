import type { DbWarning } from '@vaultsfyi/common'
import { Suspense, useEffect, useState } from 'react'
import { useRevalidator } from 'react-router'

import { TagEditor } from './TagEditor'
import { AddWarning } from './warnings/AddWarning'
import { WarningList } from './warnings/WarningsList'

import { Button } from '@/components/atoms/Button'
import { SaveIcon, UndoIcon } from '@/components/atoms/Icons'
import { Switch } from '@/components/atoms/Switch'
import { Textarea } from '@/components/atoms/Textarea'
import { Tile } from '@/components/atoms/Tile'
import { LabeledField } from '@/components/molecules/LabeledField'
import { LabeledInputField } from '@/components/molecules/LabeledInputField'
import { LabeledValueContent, LabeledValueHeader, LabeledValueValue } from '@/components/molecules/LabeledValue'
import { VaultTag } from '@/components/molecules/VaultTag'
import { NetworkStatistic, ProtocolStatistic, TokenStatistic } from '@/components/organisms/VaultStatistics'
import { trpc } from '@/config/trpc'
import { useVaultWithSuspense } from '@/hooks'
import type { BackendTag } from '@/types'
import { shortenString } from '@/utils'

interface DataChangerProps {
  selectedVault: {
    network: string
    address: string
  }
  vaultTags: BackendTag[]
  setVaultTags: (tag: BackendTag[]) => void
}

export function DataChanger({ selectedVault, vaultTags, setVaultTags }: DataChangerProps) {
  const { revalidate } = useRevalidator()
  const vault = useVaultWithSuspense({
    address: selectedVault.address,
    chainName: selectedVault.network,
    interval: '1day',
  })
  const {
    name,
    address,
    network,
    asset,
    lendLink,
    metadata,
    isCompounding,
    isFeatured,
    yieldSource,
    tags,
    isTransactionalFeatured,
  } = vault
  const { mutateAsync } = trpc.updateVaultData.useMutation()

  const [newYieldSource, setNewYieldSource] = useState<string | undefined | null>(yieldSource)
  const [newVaultName, setNewVaultName] = useState(name)
  const [newLendLink, setNewLendLink] = useState<string | undefined | null>(lendLink)
  const [newIsCompounding, setNewIsCompounding] = useState<boolean>(isCompounding)
  const [newIsFeatured, setNewIsFeatured] = useState<boolean>(isFeatured)
  const [newHasAdditionalIncentives, setNewHasAdditionalIncentives] = useState<boolean>(!!vault.additionalIncentives)
  const [newAdditionalIncentives, setNewAdditionalIncentives] = useState<string | undefined | null>(
    vault.additionalIncentives
  )
  const [newIsTransactionalFeatured, setNewIsTransactionalFeatured] = useState<boolean>(isTransactionalFeatured)
  const [warnings, setWarnings] = useState(vault.warnings)

  useEffect(() => {
    setNewYieldSource(yieldSource)
    setNewVaultName(name)
    setNewLendLink(lendLink)
    setVaultTags(tags ?? [])
    setNewIsCompounding(isCompounding)
    setNewIsFeatured(isFeatured)
    setNewIsTransactionalFeatured(isTransactionalFeatured)
    setWarnings(vault.warnings)
    setNewHasAdditionalIncentives(!!vault.additionalIncentives)
    setNewAdditionalIncentives(vault.additionalIncentives)
  }, [
    address,
    yieldSource,
    name,
    lendLink,
    tags,
    isCompounding,
    isFeatured,
    setVaultTags,
    vault.warnings,
    isTransactionalFeatured,
    vault.additionalIncentives,
  ])

  const addWarning = (warning: DbWarning) => {
    setWarnings((prevState) => [...prevState, warning])
  }

  const updateWarning = (warning: DbWarning) => {
    setWarnings((prevState) => prevState.map((prevWarning) => (prevWarning.id === warning.id ? warning : prevWarning)))
  }

  const deleteWarning = (warning: DbWarning) => {
    setWarnings((prevState) => prevState.filter((prevWarning) => prevWarning.id !== warning.id))
  }

  const [buttonDisabled, setButtonDisabled] = useState(false)

  const handleNewPassword = async () => {
    try {
      setButtonDisabled(true)

      if (newHasAdditionalIncentives && (!newAdditionalIncentives || newAdditionalIncentives === '')) {
        throw new Error('Additional incentives cannot be empty')
      }

      const { status } = await mutateAsync({
        lendLink: newLendLink,
        vaultName: newVaultName,
        address: selectedVault.address,
        network: selectedVault.network,
        yieldSource: newYieldSource,
        isCompounding: newIsCompounding,
        isFeatured: newIsFeatured,
        isTransactionalFeatured: newIsTransactionalFeatured,
        additionalIncentives: newHasAdditionalIncentives ? newAdditionalIncentives : null,
      })
      if (status === 'Success') {
        window.alert('Changed yield source successfully')
      }
    } catch (e) {
      window.alert(e)
    } finally {
      setButtonDisabled(false)
    }
  }

  const handleTagClick = (tag: BackendTag) => {
    setVaultTags(vaultTags.filter((vaultTag) => vaultTag.id !== tag.id))
  }

  const handleIsCompoundingClick = () => {
    setNewIsCompounding((prevState) => !prevState)
  }

  const handleIsFeaturedClick = () => {
    setNewIsFeatured((prevState) => !prevState)
  }

  const handleIsTransactionalFeaturedClick = () => {
    setNewIsTransactionalFeatured((prevState) => !prevState)
  }

  return (
    <div className="flex basis-full flex-col gap-6">
      <div className="grid grid-cols-[repeat(auto-fill,148px)] gap-6">
        <div>
          <LabeledValueHeader>Vault address</LabeledValueHeader>
          <LabeledValueContent>
            <LabeledValueValue>{shortenString(address)}</LabeledValueValue>
          </LabeledValueContent>
        </div>
        <NetworkStatistic network={network} />
        <TokenStatistic asset={asset} metadata={metadata} />
        <ProtocolStatistic metadata={metadata} />
      </div>
      <LabeledInputField
        id="vaultTitle"
        label="Vault title"
        value={newVaultName}
        onChange={(e) => setNewVaultName(e.target.value)}
        headerContent={
          <Button variant="default" size="default" onClick={() => setNewVaultName(name)} title="Reset vault name">
            <UndoIcon />
          </Button>
        }
      />
      <LabeledField
        id="vaultTags"
        label="Vault tags"
        headerContent={
          <Button variant="default" size="default" onClick={() => setVaultTags(tags ?? [])} title="Reset vault tags">
            <UndoIcon />
          </Button>
        }
      >
        <div className="flex flex-wrap gap-2" id="vaultTags">
          {vaultTags.length > 0 ? (
            vaultTags.map((vaultTag) => (
              <Button
                variant="default"
                size="default"
                className="rounded-2xl hocus:ring-2 ring-blue-500"
                onClick={() => handleTagClick(vaultTag)}
                key={vaultTag.id}
              >
                <VaultTag tag={vaultTag} />
              </Button>
            ))
          ) : (
            <p>Vault has no tags</p>
          )}
        </div>
      </LabeledField>
      <Tile spacing="small">
        <Suspense
          fallback={
            <p>
              <b>Loading tags...</b>
            </p>
          }
        >
          <TagEditor setVaultTags={setVaultTags} vaultTags={vaultTags} />
        </Suspense>
      </Tile>
      <LabeledField id="vaultWarnings" label="Vault warnings">
        <AddWarning vault={vault} revalidate={revalidate} addWarning={addWarning} />
        <WarningList
          warnings={warnings}
          revalidate={revalidate}
          updateWarning={updateWarning}
          deleteWarning={deleteWarning}
        />
      </LabeledField>
      <LabeledInputField
        id="vaultLendLink"
        label="Vault link"
        value={newLendLink ?? ''}
        onChange={(e) => setNewLendLink(e.target.value)}
        headerContent={
          <Button
            variant="default"
            size="default"
            onClick={() => setNewLendLink(lendLink)}
            title="Reset vault vault link"
          >
            <UndoIcon />
          </Button>
        }
      />
      <LabeledField
        id="vaultYieldSource"
        label="Yield Source"
        headerContent={
          <Button
            variant="default"
            size="default"
            onClick={() => setNewYieldSource(yieldSource)}
            title="Reset vault yield source"
          >
            <UndoIcon />
          </Button>
        }
      >
        <Textarea
          id="vaultYieldSource"
          name="vaultYieldSource"
          rows={4}
          autoCorrect="off"
          value={newYieldSource ?? ''}
          onChange={(e) => setNewYieldSource(e.target.value)}
        />
      </LabeledField>
      <LabeledField
        id="isCompounding"
        label="Is vault interest compounding?"
        headerContent={
          <Button
            variant="default"
            size="default"
            onClick={() => setNewIsCompounding(isCompounding)}
            title="Reset is vault compounding"
          >
            <UndoIcon />
          </Button>
        }
      >
        <Switch
          id="isCompounding"
          name="isCompounding"
          checked={newIsCompounding}
          onCheckedChange={handleIsCompoundingClick}
        />
      </LabeledField>
      <LabeledField
        id="isFeatured"
        label="Is vault featured/curated?"
        headerContent={
          <Button
            variant="default"
            size="default"
            onClick={() => setNewIsFeatured(isFeatured)}
            title="Reset is vault featured"
          >
            <UndoIcon />
          </Button>
        }
      >
        <Switch id="isFeatured" name="isFeatured" checked={newIsFeatured} onCheckedChange={handleIsFeaturedClick} />
      </LabeledField>
      <LabeledField
        id="isTransactionalFeatured"
        label="Is vault transactional featured?"
        headerContent={
          <Button
            variant="default"
            size="default"
            onClick={() => setNewIsTransactionalFeatured(isTransactionalFeatured)}
            title="Reset is vault transactional featured"
          >
            <UndoIcon />
          </Button>
        }
      >
        <Switch
          id="isTransactionalFeatured"
          name="isTransactionalFeatured"
          checked={newIsTransactionalFeatured}
          onCheckedChange={handleIsTransactionalFeaturedClick}
        />
      </LabeledField>
      <LabeledField
        id="hasAdditionalIncentives"
        label="Does vault have additional incentives?"
        headerContent={
          <Button
            variant="default"
            size="default"
            onClick={() => setNewHasAdditionalIncentives(!!vault.additionalIncentives)}
            title="Reset has additional incentives"
          >
            <UndoIcon />
          </Button>
        }
      >
        <Switch
          id="hasAdditionalIncentives"
          name="hasAdditionalIncentives"
          checked={newHasAdditionalIncentives}
          onCheckedChange={setNewHasAdditionalIncentives}
        />
      </LabeledField>
      <LabeledInputField
        id="vaultAdditionalIncentives"
        label="Additional incentives"
        disabled={!newHasAdditionalIncentives}
        value={newAdditionalIncentives ?? ''}
        onChange={(e) => setNewAdditionalIncentives(e.target.value)}
        headerContent={
          <Button
            variant="default"
            size="default"
            onClick={() => setNewAdditionalIncentives(vault.additionalIncentives)}
            title="Reset vault additional incentives"
          >
            <UndoIcon />
          </Button>
        }
      />
      <Button className="mt-auto gap-2 font-bold text-base" onClick={handleNewPassword} disabled={buttonDisabled}>
        <SaveIcon size={24} />
        Change data
      </Button>
    </div>
  )
}
