import { Area, CartesianGrid, ComposedChart, ReferenceArea, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'

import { TvlChartTooltip } from './TvlChartTooltip'

import { ChartContainer } from '@/components/atoms/Charts/ChartContainer'
import { ChartWatermark } from '@/components/atoms/Charts/ChartWatermark'
import { ZoomOutButton } from '@/components/atoms/Charts/ZoomOutButton'
import { useChartZoom } from '@/hooks'
import { useChartZoomedIn } from '@/hooks/charts/useChartZoomedIn'
import { useOnMouseUp } from '@/hooks/charts/useOnMouseUp'
import type { TvlChartData } from '@/types'
import { formatNumber, getChartDateLabel } from '@/utils'
import { isPointInDomain } from '@/utils/chartUtils/isPointInDomain'
import { type Asset, DAY_IN_SECONDS, HOUR_IN_SECONDS, truncateChartData } from '@vaultsfyi/common'
import { useMemo, useState } from 'react'

interface Props {
  chartData: TvlChartData[]
  zoomable: boolean
  tvlInNativeAsset?: boolean
  asset?: Asset
}

export const TvlChart = ({ chartData, zoomable, tvlInNativeAsset, asset }: Props) => {
  const {
    isZoomEnabled,
    state,
    allowDataOverflow,
    xAxisDomain,
    yAxisDomain,
    onChartMouseDown,
    onChartMouseMove,
    onChartMouseUp,
    onChartMouseLeave,
    zoomOut,
  } = useChartZoom({
    initialData: chartData as unknown as Record<string, number>[],
    dataKeys: tvlInNativeAsset ? ['tvlNative'] : ['tvl'],
    zoomable,
  })

  const truncChartData = useMemo(
    () => ({
      lowRes: truncateChartData(chartData, 'date', DAY_IN_SECONDS).filter((data) => isPointInDomain(data, xAxisDomain)),
      highRes: truncateChartData(chartData, 'date', HOUR_IN_SECONDS).filter((data) =>
        isPointInDomain(data, xAxisDomain)
      ),
    }),
    [chartData.length, xAxisDomain]
  )
  const zoomedIn = useChartZoomedIn(chartData, xAxisDomain)

  const [mouseOut, setMouseOut] = useState(true)
  useOnMouseUp(() => mouseOut && onChartMouseUp(), [mouseOut])

  return (
    <div className="relative flex flex-col">
      <ChartContainer className="h-64 min-h-64">
        <ResponsiveContainer>
          <ComposedChart
            data={zoomedIn ? truncChartData.highRes : truncChartData.lowRes}
            onMouseDown={onChartMouseDown}
            onMouseMove={onChartMouseMove}
            onMouseUp={onChartMouseUp}
            onMouseLeave={() => {
              setMouseOut(true)
              onChartMouseLeave()
            }}
            onMouseEnter={() => setMouseOut(false)}
          >
            <defs>
              <linearGradient id="tvlChartFill" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor="rgba(var(--chart-blue),0.7)" stopOpacity={1} />
                <stop offset="100%" stopColor="rgba(var(--chart-blue),0.1)" stopOpacity={1} />
              </linearGradient>
            </defs>
            <CartesianGrid strokeDasharray="3 3" horizontal={false} />
            <XAxis
              dataKey="date"
              scale="time"
              tickFormatter={(tick) => {
                if (typeof xAxisDomain[1] === 'number' && tick > xAxisDomain[1]) {
                  return getChartDateLabel(xAxisDomain[1])
                }
                return getChartDateLabel(tick)
              }}
              minTickGap={16}
              tickLine={false}
              allowDataOverflow={allowDataOverflow}
              domain={xAxisDomain}
              type={isZoomEnabled ? 'number' : 'category'}
            />
            <YAxis
              tickFormatter={(value) => formatNumber(value, 0, 1)}
              width={38}
              tickLine={false}
              allowDataOverflow={allowDataOverflow}
              domain={yAxisDomain}
            />
            <Tooltip content={<TvlChartTooltip tvlInNativeAsset={tvlInNativeAsset} asset={asset} />} />
            <Area
              type="monotone"
              dataKey={tvlInNativeAsset ? 'tvlNative' : 'tvl'}
              stroke="rgba(var(--chart-blue))"
              strokeWidth={1.5}
              fill="url(#tvlChartFill)"
              fillOpacity={1}
              isAnimationActive={false}
            />
            {isZoomEnabled && state.dataKeyAreaLeft && state.dataKeyAreaRight ? (
              <ReferenceArea
                x1={state.dataKeyAreaLeft}
                x2={state.dataKeyAreaRight}
                className="[&>path]:fill-background-300/50 [&>path]:stroke-background-300"
                ifOverflow="visible"
              />
            ) : null}
          </ComposedChart>
        </ResponsiveContainer>
        {isZoomEnabled && (
          <ZoomOutButton onClick={zoomOut} isVisible={state.left !== 'dataMin' && state.right !== 'dataMax'} />
        )}
        <ChartWatermark variant="square" />
      </ChartContainer>
    </div>
  )
}
