import { cn } from '@/utils/cn'
import * as SwitchPrimitives from '@radix-ui/react-switch'
import { type ComponentPropsWithoutRef, type ElementRef, forwardRef } from 'react'

export const Switch = forwardRef<
  ElementRef<typeof SwitchPrimitives.Root>,
  ComponentPropsWithoutRef<typeof SwitchPrimitives.Root>
>(({ className, ...props }, ref) => (
  <SwitchPrimitives.Root
    className={cn(
      'peer inline-flex h-6 w-12 shrink-0 cursor-pointer items-center rounded-full border border-background-300 bg-background-100 p-1 outline-none transition-colors',
      'hocus:border-blue-500',
      'disabled:cursor-not-allowed disabled:opacity-50',
      'data-[state=checked]:border-blue-500 data-[state=checked]:bg-blue-500',
      className
    )}
    {...props}
    ref={ref}
  >
    <SwitchPrimitives.Thumb
      data-role="thumb"
      className={cn(
        'pointer-events-none block size-4 rounded-full bg-background-300 transition-transform',
        'data-[state=unchecked]:-translate-x-px data-[state=checked]:translate-x-[23px] data-[state=checked]:bg-background-100'
      )}
    />
  </SwitchPrimitives.Root>
))
Switch.displayName = SwitchPrimitives.Root.displayName
