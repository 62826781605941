import { getImageUrl } from './getImageUrl'

export const getTokenImage = (symbol: string | 'fallback') => {
  switch (symbol.toLowerCase()) {
    case 'btc.b':
      return getImageUrl('tokens', 'btc')
    case 'dai.e':
      return getImageUrl('tokens', 'dai')
    case 'usdc.e':
      return getImageUrl('tokens', 'usdc')
    case 'usdt.e':
      return getImageUrl('tokens', 'usdt')
    case 'weth.e':
      return getImageUrl('tokens', 'weth')
    case 'wpol':
      return getImageUrl('tokens', 'matic')
    case 'wstusr':
      return getImageUrl('tokens', 'usr')
    case 'wusdm':
      return getImageUrl('tokens', 'usdm')
    case 'usd₮':
      return getImageUrl('tokens', 'usdt')

    case 'fallback':
      return getImageUrl('tokens', 'unknownToken')

    default:
      return getImageUrl('tokens', symbol.toLowerCase())
  }
}
