const validTemplateSymbols = ['token']
const templateSymbolRegex = /\{\{([^}]+)}}/g

interface TemplateValues {
  token: string
}

export function validateTemplateString(text: string) {
  const invalidPlaceholders =
    text.match(templateSymbolRegex)?.filter((key) => !validTemplateSymbols.includes(key.slice(2, -2))) ?? []
  if (invalidPlaceholders.length > 0) {
    throw new Error(`Invalid template placeholders: ${invalidPlaceholders.join(', ')}`)
  }
}

export function replaceTemplateValues(text: string, data: TemplateValues, logFailure = true): string {
  return text.replace(templateSymbolRegex, (_, key) => {
    const value = data[key as keyof TemplateValues]
    if (logFailure && value === undefined) console.warn(`Template value not found for key: ${key}`)
    return value ?? ''
  })
}

interface GenericVault {
  description?: string
  token: {
    symbol: string
  }
}

export function parseVaultDescription(vault: GenericVault, logFailure = true) {
  if (!vault.description) return undefined
  const TemplateValues: TemplateValues = {
    token: vault.token.symbol,
  }

  return replaceTemplateValues(vault.description, TemplateValues, logFailure).replace(/<br\/>/g, '\n')
}
