import { cn } from '@/utils/cn'
import * as SheetPrimitive from '@radix-ui/react-dialog'
import { type ComponentPropsWithoutRef, type ElementRef, type HTMLAttributes, forwardRef } from 'react'
import { Button } from './Button'
import { CrossIcon } from './Icons'

export const Sheet = SheetPrimitive.Root

export const SheetTrigger = SheetPrimitive.Trigger

const SheetPortal = SheetPrimitive.Portal

export const SheetOverlay = forwardRef<
  ElementRef<typeof SheetPrimitive.Overlay>,
  ComponentPropsWithoutRef<typeof SheetPrimitive.Overlay>
>(({ className, ...props }, ref) => (
  <SheetPrimitive.Overlay
    className={cn(
      'data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 fixed inset-0 z-50 bg-overlay/70 data-[state=closed]:animate-out data-[state=open]:animate-in',
      className
    )}
    data-role="sheet-overlay"
    {...props}
    ref={ref}
  />
))
SheetOverlay.displayName = SheetPrimitive.Overlay.displayName

export const SheetContent = forwardRef<
  ElementRef<typeof SheetPrimitive.Content>,
  ComponentPropsWithoutRef<typeof SheetPrimitive.Content>
>(({ className, ...props }, ref) => (
  <SheetPortal>
    <SheetPrimitive.Content
      ref={ref}
      className={cn(
        'fixed z-50 bg-background-100 shadow-card transition ease-in-out data-[state=closed]:animate-out data-[state=open]:animate-in data-[state=closed]:duration-300 data-[state=open]:duration-500',
        'data-[state=closed]:slide-out-to-right data-[state=open]:slide-in-from-right inset-y-0 right-0 h-full w-3/4 rounded-l-2xl border-background-300 border-l sm:max-w-sm',
        className
      )}
      {...props}
    />
  </SheetPortal>
))
SheetContent.displayName = SheetPrimitive.Content.displayName

export const SheetClose = forwardRef<
  ElementRef<typeof SheetPrimitive.Close>,
  ComponentPropsWithoutRef<typeof SheetPrimitive.Close>
>(({ className, ...props }, ref) => (
  <SheetPrimitive.Close asChild>
    <Button variant="default" size="default" ref={ref} className={cn('ml-auto', className)} {...props}>
      <CrossIcon className="size-6" />
      <span className="sr-only">Close</span>
    </Button>
  </SheetPrimitive.Close>
))
SheetClose.displayName = SheetPrimitive.Content.displayName

export const SheetHeader = ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      'flex flex-col space-y-2 border-background-300 border-b px-4 py-2 text-center sm:text-left',
      className
    )}
    {...props}
  />
)
SheetHeader.displayName = 'SheetHeader'

export const SheetFooter = ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => (
  <div className={cn('mt-auto border-background-300 border-t px-4 py-2', className)} {...props} />
)
SheetFooter.displayName = 'SheetFooter'
