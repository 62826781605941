import { formatNumber } from '@/utils'

interface PieChartCustomLabelProps {
  cx: number
  cy: number
  innerRadius: number
  outerRadius: number
  midAngle: number
  payload: Record<string, any>
}

const RADIAN = Math.PI / 180
const VISIBLE_PERCENTAGE_THRESHOLD = 5

export const HoldersChartLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  payload,
}: PieChartCustomLabelProps) => {
  const percentage = payload.payload.proportion

  if (percentage < VISIBLE_PERCENTAGE_THRESHOLD) {
    return null
  }

  const radius = innerRadius + (outerRadius - innerRadius) * 0.5
  const x = cx + radius * Math.cos(-midAngle * RADIAN)
  const y = cy + radius * Math.sin(-midAngle * RADIAN)

  return (
    <text
      className="customChartCellLabel pointer-events-none fill-foreground-300 font-bold"
      x={x}
      y={y}
      textAnchor="middle"
      dominantBaseline="central"
    >
      {formatNumber(Math.floor(percentage), 0, 0)}%
    </text>
  )
}
