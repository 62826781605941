import { BN_ONE_IN_PERCENT } from '@/constants'
import { formatNumber, formatUnits } from '@/utils'
import type { Asset } from '@vaultsfyi/common'

export interface ProportionsResult {
  key: string
  name: string
  value: number
  valueInUsd: number
  formattedValue?: string
  legendValue?: string
  colors: string[]
}

interface GetProportionsChartDataProps {
  tvlInToken: string | undefined
  tvlInUsd: number | undefined
  liquidityInToken: string | undefined
  asset: Asset | undefined
}

const precisionDecimals = 5
const precision = BigInt(10 ** precisionDecimals)

export const getProportionsChartData = ({
  tvlInToken,
  tvlInUsd,
  liquidityInToken,
  asset,
}: GetProportionsChartDataProps): ProportionsResult[] => {
  const tvl = BigInt(tvlInToken ?? '0')
  const parsedLiquidity = BigInt(liquidityInToken ?? '0')
  const liquidity = parsedLiquidity > tvl ? tvl : parsedLiquidity

  const lockedAssets = tvl - liquidity
  const liquidityPart = (liquidity * BN_ONE_IN_PERCENT * precision) / tvl
  const lockedAssetsPart = (lockedAssets * BN_ONE_IN_PERCENT * precision) / tvl

  return [
    {
      key: 'locked',
      name: 'Locked',
      value: Number(lockedAssets) ?? 0,
      valueInUsd: tvlInUsd ? tvlInUsd * (Number(formatUnits(lockedAssetsPart, precisionDecimals)) / 100) : 0,
      formattedValue: asset
        ? `${formatNumber(formatUnits(BigInt(lockedAssets), asset.decimals))} ${asset.symbol}`
        : '-',
      legendValue: formatLegendValue(lockedAssetsPart),
      colors: getChartColors('Locked'),
    },
    {
      key: 'liquid',
      name: 'Liquid',
      value: Number(liquidityInToken) ?? 1,
      valueInUsd: tvlInUsd ? tvlInUsd * (Number(formatUnits(liquidityPart, precisionDecimals)) / 100) : 0,
      formattedValue:
        liquidityInToken && asset
          ? `${formatNumber(formatUnits(BigInt(liquidityInToken), asset.decimals))} ${asset.symbol}`
          : '-',
      legendValue: formatLegendValue(liquidityPart),
      colors: getChartColors('Liquid'),
    },
  ]
}

function formatLegendValue(value: bigint): string {
  return `${formatNumber(Number(value) / Number(precision))}%`
}

function getChartColors(name: string): string[] {
  switch (name) {
    case 'Locked':
      return ['rgba(var(--chart-pink),1)', 'rgba(var(--chart-pink),1)']
    case 'Liquid':
    default:
      return ['rgba(var(--chart-blue),1)', 'rgba(var(--chart-blue),1)']
  }
}
