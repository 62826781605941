import { QuestionCircleIcon } from '@/components/atoms/Icons'
import { Markdown } from '@/components/atoms/Markdown'
import type { BackendVaultDetailed } from '@/types'

interface Props {
  vault: Pick<BackendVaultDetailed, 'yieldSource' | 'asset'>
}

export function PreviewYieldSource({ vault }: Props) {
  return (
    <div className="flex flex-col gap-3 sm:flex-row">
      <div className="flex size-12 min-w-12 items-center justify-center rounded-full bg-blue-500/10 text-blue-500">
        <QuestionCircleIcon size={24} />
      </div>
      <div className="flex flex-col items-start justify-center gap-2">
        <h4 className="text-base text-foreground-300 leading-none">Where does the yield come from?</h4>
        <Markdown>{vault.yieldSource}</Markdown>
      </div>
    </div>
  )
}
