import { ChartTooltip } from '@/components/atoms/Charts/ChartTooltip'
import { LabeledValueContent, LabeledValueHeader, LabeledValueValue } from '@/components/molecules/LabeledValue'
import { type ProportionsResult, formatNumber, formatUnits } from '@/utils'

export const AssetDetailsChartTooltip = (props: any) => {
  const { payload, tvlInNativeAsset, asset } = props

  if (!payload) {
    return <></>
  }

  return (
    <ChartTooltip>
      {payload.map((item: Record<string, any>, index: number) => {
        const payload: ProportionsResult = item.payload
        return (
          <div className="text-foreground-100" key={payload.key + index}>
            <LabeledValueHeader className="!text-xs">{payload.name}</LabeledValueHeader>
            <LabeledValueContent>
              <LabeledValueValue className="!text-base">
                {tvlInNativeAsset
                  ? `${formatNumber(formatUnits(BigInt(payload.value), asset.decimals))} ${asset.symbol}`
                  : `$${formatNumber(payload.valueInUsd)}`}
              </LabeledValueValue>
            </LabeledValueContent>
          </div>
        )
      })}
    </ChartTooltip>
  )
}
