import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts'

import { ChartContainer } from '@/components/atoms/Charts/ChartContainer'
import { ChartWatermark } from '@/components/atoms/Charts/ChartWatermark'
import { HoldersMissingChartTooltip } from './HoldersMissingTooltip'

const DUMMY_DATA = [1, 2, 3, 4, 5].map((index) => ({
  label: `Holder ${index}`,
  value: 100,
  proportion: index * 20,
  explorerLink: undefined,
  color: `rgba(var(--chart-gray), ${1 - index * 0.15})`,
}))

const CHART_RADIUS = 112
const CHART_SIZE = CHART_RADIUS * 2
const REST_HOLDERS_PATTERN_KEY = 'rest-holders-pattern'

export const HoldersMissingChart = () => {
  return (
    <div className="relative mx-auto flex w-fit items-center justify-center">
      <ChartContainer className="aspect-square min-h-56 w-56">
        <ResponsiveContainer>
          <PieChart>
            <defs>
              <pattern
                id={REST_HOLDERS_PATTERN_KEY}
                x="0"
                y="0"
                width={CHART_SIZE}
                height={CHART_SIZE}
                patternUnits="userSpaceOnUse"
                patternContentUnits="userSpaceOnUse"
              >
                <circle
                  cx={CHART_RADIUS}
                  cy={CHART_RADIUS}
                  r={CHART_RADIUS * 0.9}
                  fill={DUMMY_DATA[DUMMY_DATA.length - 1].color}
                />
              </pattern>
            </defs>
            <Pie
              data={DUMMY_DATA}
              cx="50%"
              cy="50%"
              labelLine={false}
              outerRadius={CHART_RADIUS}
              dataKey="proportion"
              isAnimationActive={false}
              startAngle={90}
              endAngle={-270}
            >
              {DUMMY_DATA.map(({ color }, index) => (
                <Cell key={`cell-${index}`} fill={color} className={'customChartCell'} stroke="transparent" />
              ))}
            </Pie>
            <Tooltip content={<HoldersMissingChartTooltip />} />
          </PieChart>
        </ResponsiveContainer>
        <ChartWatermark variant="circle" />
      </ChartContainer>
    </div>
  )
}
