import type { ChainConfig } from '../..'
import { ETH } from '../assets'

export const BASE = {
  name: 'base',
  explorerUrl: 'https://basescan.org',
  nativeAsset: ETH,
  slip44: 60,
  wrappedNativeAsset: '0x4200000000000000000000000000000000000006',
  blockscoutApiUrl: 'https://base.blockscout.com/api',
  etherscanApiUrl: 'https://api.basescan.org/api',
  publicRpcUrls: [
    'https://base.llamarpc.com',
    'https://mainnet.base.org',
    'https://1rpc.io/base',
    'https://base.drpc.org',
  ],
  getPrivateRpcUrls: () => {
    const base: string[] = []
    process.env.INFURA_API_KEY && base.push(`https://base-mainnet.infura.io/v3/${process.env.INFURA_API_KEY}`)
    process.env.ALCHEMY_BASE_API_KEY &&
      base.push(`https://base-mainnet.g.alchemy.com/v2/${process.env.ALCHEMY_BASE_API_KEY}`)
    process.env.ANKR_API_KEY && base.push(`https://rpc.ankr.com/base/${process.env.ANKR_API_KEY}`)
    return base
  },
} as const satisfies ChainConfig
