import { Button, type ButtonProps } from '@/components/atoms/Button'
import { ArrowIcon } from '@/components/atoms/Icons'
import { cn } from '@/utils/cn'

export const LoadMoreButton = ({ className, ...props }: ButtonProps) => {
  return (
    <Button
      className={cn('m-auto items-baseline gap-2 underline', className)}
      variant="default"
      size="default"
      {...props}
    >
      Load more
      <ArrowIcon direction="down" />
    </Button>
  )
}
