import { Button } from '@/components/atoms/Button'
import { Container } from '@/components/atoms/Container'
import { routes } from '@/config/routes'
import { trackPageEvents } from '@/pages/Layout/hooks'
import { NavLink } from 'react-router'

const WALLFACER_LABS_URL = 'https://wallfacer.io/'

export const Footer = () => {
  const handleAuthorLinkEvent = () => {
    trackPageEvents({ label: 'Footer Wallfacer link clicked' })
  }

  return (
    <footer className="py-2">
      <Container>
        <div className="flex flex-wrap items-center justify-between gap-x-4 gap-y-2">
          <p className="text-foreground-100">
            Created by{' '}
            <Button
              variant="default"
              size="default"
              className="text-base underline"
              onClick={handleAuthorLinkEvent}
              asChild
            >
              <a href={WALLFACER_LABS_URL} target="_blank" rel="noopener noreferrer">
                wallfacer labs
              </a>
            </Button>
            , 2023
          </p>
          <Button variant="default" size="default" className="text-base underline" asChild>
            <NavLink to={routes.termsOfService} target="_blank">
              Terms of Service
            </NavLink>
          </Button>
        </div>
      </Container>
    </footer>
  )
}
