import { type ChangeEvent, useCallback, useMemo, useState } from 'react'

import {
  Dropdown,
  DropdownContent,
  DropdownInput,
  DropdownItem,
  DropdownItemContent,
  DropdownItemText,
  DropdownList,
  DropdownListItem,
  DropdownSeparator,
  DropdownTrigger,
} from '@/components/atoms/Dropdown'
import { Image } from '@/components/atoms/Image'
import { Logo } from '@/components/atoms/Logo'
import { ScrollArea } from '@/components/atoms/ScrollArea'
import { CHART_EXPLORER_TOUCHED } from '@/constants'
import type { BackendVault } from '@/types'
import { fuzzySearchVaults, getLocalStorageValue, setLocalStorageValue } from '@/utils'
import { cn } from '@/utils/cn'
import { getNetworkImage, getProtocolImage, getVaultKey } from '@vaultsfyi/common'
import type { VaultsBenchmark } from '@vaultsfyi/common'
import { isAddressEqual } from 'viem'

interface VaultsSelectProps {
  vaults: BackendVault[]
  benchmarks: VaultsBenchmark[]
  selectedBenchmarks: string[]
  setSelectedBenchmarks: (benchmarks: string[]) => void
  selectedVaults: string[]
  setSelectedVaults: (vaults: string[]) => void
  name: string
}

export const VaultsSelect = ({ setSelectedVaults, vaults, selectedBenchmarks, ...props }: VaultsSelectProps) => {
  const [search, setSearch] = useState<string>('')
  const onSearchChange = useCallback((event: ChangeEvent<HTMLInputElement>) => setSearch(event.target.value), [])

  const selectedVaults = useMemo(
    () => vaults.filter((vault) => props.selectedVaults.includes(getVaultKey(vault))),
    [props.selectedVaults, vaults]
  )

  const filteredVaults = useMemo(() => {
    return fuzzySearchVaults(vaults, search).sort((a, b) => {
      const aInSelected =
        selectedVaults.findIndex((vault) => isAddressEqual(a.address, vault.address) && a.network === vault.network) >
        -1
      const bInSelected =
        selectedVaults.findIndex((vault) => isAddressEqual(b.address, vault.address) && b.network === vault.network) >
        -1
      if (aInSelected && !bInSelected) return -1
      if (!aInSelected && bInSelected) return 1
      return (b.tvlInUsd ?? 0) - (a.tvlInUsd ?? 0)
    })
  }, [selectedVaults, vaults, search, fuzzySearchVaults])
  const [isTouched, setTouched] = useState<boolean>(Boolean(getLocalStorageValue(CHART_EXPLORER_TOUCHED)))

  const handleTouch = () => {
    setLocalStorageValue(CHART_EXPLORER_TOUCHED, true)
    setTouched(true)
  }

  const selectedChartsCount = selectedVaults.length + selectedBenchmarks.length

  return (
    <Dropdown>
      <DropdownTrigger className={cn(!isTouched && 'repeat-infinite animate-input-glow')} onClick={handleTouch}>
        {selectedChartsCount === 0 ? (
          'Select vaults'
        ) : selectedBenchmarks.length === 0 && selectedVaults.length === 1 ? (
          <VaultText vault={selectedVaults[0]} />
        ) : selectedBenchmarks.length === 1 && selectedVaults.length === 0 ? (
          <BenchmarkText name={selectedBenchmarks[0]} />
        ) : (
          `${selectedChartsCount} charts selected`
        )}
      </DropdownTrigger>
      <DropdownContent>
        <DropdownInput placeholder={`Find ${name}`} onChange={onSearchChange} value={search} inputMode="text" />
        <DropdownSeparator />
        <ScrollArea className="h-64 w-full">
          <div className="max-w-full overflow-hidden">
            {props.benchmarks.length > 0 && (
              <>
                <div className="px-3 py-2 text-base">Benchmarks:</div>
                <DropdownList className="py-2 pt-0">
                  {props.benchmarks.map((benchmark) => (
                    <SelectBenchmark
                      key={benchmark.name}
                      benchmark={benchmark}
                      selectedBenchmarks={selectedBenchmarks}
                      setSelectedBenchmarks={props.setSelectedBenchmarks}
                    />
                  ))}
                </DropdownList>
                <DropdownSeparator />
              </>
            )}
            {filteredVaults.length > 0 && (
              <DropdownList>
                {filteredVaults.map((vault) => (
                  <SelectVault
                    key={getVaultKey(vault)}
                    vault={vault}
                    setSelectedVaults={setSelectedVaults}
                    selectedBenchmarks={selectedBenchmarks}
                    {...props}
                  />
                ))}
              </DropdownList>
            )}
          </div>
        </ScrollArea>
      </DropdownContent>
    </Dropdown>
  )
}

interface SelectVaultProps extends Omit<VaultsSelectProps, 'vaults'> {
  vault: BackendVault
  setSelectedVaults: (vaults: string[]) => void
}

const SelectVault = ({ vault, selectedVaults, setSelectedVaults }: SelectVaultProps) => {
  const vaultKey = getVaultKey(vault)
  const isItemSelected = selectedVaults.includes(vaultKey)
  const handleItemClick = () =>
    setSelectedVaults(
      isItemSelected ? selectedVaults.filter((itemInArray) => itemInArray !== vaultKey) : [...selectedVaults, vaultKey]
    )

  return (
    <DropdownListItem>
      <DropdownItem onClick={handleItemClick} isActive={isItemSelected}>
        <VaultText vault={vault} />
      </DropdownItem>
    </DropdownListItem>
  )
}

interface SelectBenchmarkProps {
  benchmark: VaultsBenchmark
  selectedBenchmarks: string[]
  setSelectedBenchmarks: (benchmarks: string[]) => void
}

const SelectBenchmark = ({ benchmark, selectedBenchmarks, setSelectedBenchmarks }: SelectBenchmarkProps) => {
  const isItemSelected = selectedBenchmarks.includes(benchmark.name)
  const handleItemClick = () =>
    setSelectedBenchmarks(
      isItemSelected
        ? selectedBenchmarks.filter((itemInArray) => itemInArray !== benchmark.name)
        : [...selectedBenchmarks, benchmark.name]
    )

  return (
    <DropdownListItem>
      <DropdownItem onClick={handleItemClick} isActive={isItemSelected}>
        <BenchmarkText name={benchmark.name} />
      </DropdownItem>
    </DropdownListItem>
  )
}

function VaultText({ vault }: { vault: BackendVault }) {
  return (
    <DropdownItemContent>
      <Image src={getNetworkImage(vault.network)} alt={`${vault.network} image`} loading="lazy" className="min-w-6" />
      <Image
        src={getProtocolImage(vault.protocolName)}
        alt={`${vault.protocolName} image`}
        loading="lazy"
        className="min-w-6"
      />
      <DropdownItemText className="first-letter:uppercase">{vault.name}</DropdownItemText>
    </DropdownItemContent>
  )
}

function BenchmarkText({ name }: { name: string }) {
  return (
    <DropdownItemContent>
      <Logo className="size-6 rounded-none text-foreground-300 md:size-6" />
      <DropdownItemText className="first-letter:uppercase">{name}</DropdownItemText>
    </DropdownItemContent>
  )
}
