import type { DbWarning } from '@vaultsfyi/common'

import { DeleteWarning } from './DeleteWarning'
import { EditWarning, EditWarningButton } from './EditWarning'

import { Accordion, AccordionContent, AccordionItem } from '@/components/atoms/Accordion'
import { Panel } from '@/components/atoms/Panel'
import { Warning } from '@/components/molecules/Warning/Warning'
import type { BackendWarning, EditWarningIdProps, RevalidatorProps } from '@/types'

interface WarningEditorProps extends BackendWarning, RevalidatorProps, EditWarningIdProps {
  updateWarning: (warning: DbWarning) => void
  deleteWarning: (warning: DbWarning) => void
}

export const WarningEditor = ({ revalidate, updateWarning, deleteWarning, ...props }: WarningEditorProps) => {
  return (
    <Accordion type="multiple">
      <AccordionItem value="Edit warning accordion">
        <Panel className="bg-orange-500 p-0">
          <div className="flex items-center justify-end gap-4 px-4 pt-2">
            <EditWarningButton />
            <DeleteWarning warningId={props.warningId} revalidate={revalidate} deleteWarning={deleteWarning} />
          </div>
          <Warning content={props.content} />
          <AccordionContent>
            <EditWarning revalidate={revalidate} {...props} warningId={props.warningId} updateWarning={updateWarning} />
          </AccordionContent>
        </Panel>
      </AccordionItem>
    </Accordion>
  )
}
