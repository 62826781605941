import type { VaultForOptimizer } from '@/types'
import { ANALYTICS_URL } from '@vaultsfyi/common'

export const vaultMock: VaultForOptimizer = {
  name: 'Vault Example',
  address: '0x0000000000000000000000000000000000000000',
  network: 'mainnet',
  asset: {
    name: 'USD Coin',
    assetAddress: '0x7ea2be2df7ba6e54b1a9c70676f668455e329d29',
    symbol: 'USDC',
    decimals: 6,
  },
  apy: 1000,
  yieldInUsd: 1000,
  rawApy: 600,
  rawYieldInToken: 600,
  rewardsApy: 400,
  rewards: [
    {
      asset: {
        name: 'USD Coin',
        assetAddress: '0x7ea2be2df7ba6e54b1a9c70676f668455e329d29',
        symbol: 'USDC',
        decimals: 6,
      },
      yieldInToken: 100,
    },
  ],
  txCostsInNativeCurrency: 0.01,
  annualPercentageLossOnTxCosts: 0,
  nativeCurrency: 'eth',
  lendLink: ANALYTICS_URL,
  tags: [{ id: 0, name: 'Placeholder', color: '#596f78' }],
  chartData: [],
  isTransactional: false,
}
