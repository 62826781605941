import type { TextareaHTMLAttributes } from 'react'

import { cn } from '@/utils/cn'

export const Textarea = ({ className, ...props }: TextareaHTMLAttributes<HTMLTextAreaElement>) => {
  return (
    <textarea
      className={cn(
        'h-10 w-full text-base',
        'rounded-lg border border-background-300 bg-background-100 px-4 py-2 outline-none transition-colors',
        'hocus:border-blue-500',
        'placeholder:text-base placeholder:text-foreground-100',
        'disabled:cursor-not-allowed disabled:opacity-50',
        className
      )}
      {...props}
    />
  )
}
