import type { Network } from '../../types'
import { ARBITRUM } from './arbitrum'
import { BASE } from './base'
import { CELO } from './celo'
import { GNOSIS } from './gnosis'
import { MAINNET } from './mainnet'
import { OPTIMISM } from './optimism'
import { POLYGON } from './polygon'
import { SWELLCHAIN } from './swellchain'
import { UNICHAIN } from './unichain'

export const CHAINS = [MAINNET, OPTIMISM, ARBITRUM, POLYGON, GNOSIS, BASE, UNICHAIN, SWELLCHAIN, CELO] as const

export const CHAINS_BY_NAME = Object.fromEntries(CHAINS.map((chain) => [chain.name, chain])) as Record<
  Network,
  (typeof CHAINS)[number]
>
