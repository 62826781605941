import { cn } from '@/utils/cn'
import type { ReactMarkdownOptions } from 'react-markdown/lib/react-markdown'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown'
import remarkBreaks from 'remark-breaks'
import remarkGfm from 'remark-gfm'

export function Markdown({ className, ...props }: ReactMarkdownOptions) {
  return (
    <ReactMarkdown
      remarkPlugins={[remarkGfm, remarkBreaks]}
      components={components}
      className={cn('prose prose-sm max-w-full text-base', '[&_a]:hocus:text-blue-500 [&_a]:text-blue-500', className)}
      {...props}
    />
  )
}

const components: ReactMarkdownOptions['components'] = {
  a: (props) => <a {...props} target="_blank" />,
}
