import type { ChainConfig } from '../..'
import { ETH } from '../assets'

export const MAINNET = {
  name: 'mainnet',
  explorerUrl: 'https://etherscan.io',
  nativeAsset: ETH,
  slip44: 60,
  wrappedNativeAsset: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
  blockscoutApiUrl: 'https://eth.blockscout.com/api',
  etherscanApiUrl: 'https://api.etherscan.io/api',
  publicRpcUrls: [
    'https://eth.drpc.org',
    'https://mainnet.gateway.tenderly.co',
    'https://1rpc.io/eth',
    'https://rpc.eth.gateway.fm',
  ],
  getPrivateRpcUrls: () => {
    const mainnet: string[] = []
    process.env.ALCHEMY_MAINNET_API_KEY &&
      mainnet.push(`https://eth-mainnet.g.alchemy.com/v2/${process.env.ALCHEMY_MAINNET_API_KEY}`)
    process.env.INFURA_API_KEY && mainnet.push(`https://mainnet.infura.io/v3/${process.env.INFURA_API_KEY}`)
    process.env.ANKR_API_KEY && mainnet.push(`https://rpc.ankr.com/eth/${process.env.ANKR_API_KEY}`)
    return mainnet
  },
} as const satisfies ChainConfig
